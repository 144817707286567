<template>
  <div class="h-full w-full">
    <div class="h-full w-full" v-if="!showPrivilegeError">
      <div class="mx-3 -mt-1">
        <Card class="p-5">
          <template v-slot:footer>
            <card-footer
              printcard
              reloadcard
              show-selection
              @print="print"
              @reload="reload()"
              @sortType="handleSort($event)"
              @option="handleOptionSelect($event)"
              @searchResult="handleSearch($event)"
              @actionModal="viewFilterTable($event)"
            />
          </template>
        </Card>
      </div>
      <div class="w-full">
        <div class="w-full h-full p-3 mb-8">
          <sTable
            :headers="employeeHeader"
            :items="employees"
            style="width: 100%"
            :loading="loading"
            aria-label="employee table"
            class="w-full"
            id="printMe"
            :pagination-list="metaData"
            @page="handlePage($event)"
            @itemsPerPage="handleItemsPerPage($event)"
            page-sync
            has-number
          >
            <template v-slot:item="{ item }">
              <template v-if="item.userId">
                <div
                  class="flex flex-row items-center"
                  @click="viewEmployeeDetails(item.data)"
                >
                  <template>
                    <div class="flex w-2 h-2 rounded mr-2">
                      <span
                        class="p-1 rounded"
                        :class="
                          item.data.employmentStatus === 'confirmed'
                            ? 'bg-mediumSeaGreen'
                            : item.data.employmentStatus === 'suspended'
                            ? 'bg-carrotOrange'
                            : item.data.employmentStatus === 'disengaged'
                            ? 'bg-flame'
                            : item.data.employmentStatus === 're-engaged'
                            ? 'bg-mediumSeaGreen'
                            : 'bg-romanSilver'
                        "
                      />
                    </div>
                  </template>
                  <template>
                    <div>
                      <img
                        v-if="item.data.photo"
                        :src="item.data.photo"
                        alt="profile pic"
                        class="w-8 h-8 rounded"
                      />
                      <div
                        v-else
                        class="w-8 h-8 rounded text-blueCrayola border text-center font-semibold pt-2"
                      >
                        {{
                          $getInitials(`${item.data.fname} ${item.data.lname}`)
                        }}
                      </div>
                    </div>
                  </template>
                  <div class="ml-2 text-sm flex flex-col justify-Start">
                    <span class="font-bold cursor-pointer text-jet">
                      {{ item.data.fname }} {{ item.data.lname }}
                    </span>
                  </div>
                </div>
              </template>
              <span v-else-if="item.email" class="text-center">
                {{ !item.data.email ? "-" : item.data.email }}
              </span>
              <span v-else-if="item.phoneNo" class="text-center">
                {{ !item.data.phoneNo ? "-" : item.data.phoneNo }}
              </span>
              <span v-else-if="item.orgFunction" class="text-center">
                {{ !item.data.orgFunction ? "-" : item.data.orgFunction.name }}
              </span>
              <span v-else-if="item.userDesignation" class="text-center">
                {{
                  item.data.userDesignation
                    ? item.data.userDesignation.name
                    : "-"
                }}
              </span>
              <span v-else-if="item.userLevel" class="text-center">
                {{ item.data.userLevel ? item.data.userLevel.name : "-" }}
              </span>
              <p v-else-if="item.userReportingTo" class="text-left">
                <span v-if="item.data.userReportingTo">
                  {{ item.data.userReportingTo.fname }}
                  {{ item.data.userReportingTo.lname }}
                </span>
                <span v-else>-</span>
              </p>
              <div
                v-else-if="item.temporaryStatus"
                class="text-darkPurple capitalize"
              >
                <p v-if="item.data.temporaryStatus">
                  {{
                    item.data.temporaryStatus.replace(
                      /([a-z](?=[A-Z]))/g,
                      "$1 "
                    )
                  }}
                </p>
                <p v-else>None</p>
              </div>
              <span v-else-if="item.createdAt" class="mr-4 text-center">
                {{
                  $DATEFORMAT(new Date(item.data.createdAt), "MMMM dd, yyyy")
                }}
              </span>
              <span v-else-if="item.id">
                <Menu left top="-150" margin="24" class="my-2 p-0">
                  <template v-slot:title>
                    <icon icon-name="more_icon" size="xs" />
                  </template>
                  <div
                    class="w-48 flex flex-col justify-start items-center gap-3 px-3 py-2"
                  >
                    <div
                      class="w-full"
                      @click="handleOptionClick('view', item.data)"
                    >
                      <div
                        class="w-full flex justify-start items-center gap-2 cursor-pointer"
                      >
                        <Icon
                          icon-name="edit"
                          class-name="text-blueCrayola"
                          size="xs"
                        />
                        <p class="font-normal text-sm text-darkPurple">
                          View & Edit
                        </p>
                      </div>
                    </div>
                    <div
                      class="w-full"
                      @click="handleOptionClick('update', item.data)"
                    >
                      <div
                        class="w-full flex justify-start items-center gap-2 cursor-pointer"
                      >
                        <Icon
                          icon-name="icon-upgrade"
                          class-name="text-mediumSeaGreen"
                          size="xs"
                        />
                        <p class="font-normal text-sm text-darkPurple">
                          Resend Credentials
                        </p>
                      </div>
                    </div>
                    <div
                      class="w-full"
                      @click="handleOptionClick('suspend', item.data)"
                    >
                      <div
                        class="w-full flex justify-start items-center gap-2 cursor-pointer"
                      >
                        <Icon
                          icon-name="icon-sand-clock"
                          class-name="text-carrotOrange"
                          size="xs"
                        />
                        <p class="font-normal text-sm text-darkPurple">
                          Suspend
                        </p>
                      </div>
                    </div>
                    <!-- <div
                      class="w-full"
                      @click="handleOptionClick('salary', item.data)"
                    >
                      <div
                        class="w-full flex justify-start items-center gap-2 cursor-pointer"
                      >
                        <Icon
                          icon-name="undo"
                          class-name="text-queenPink"
                          size="xs"
                        />
                        <p class="font-normal text-sm text-darkPurple">
                          Revise Salary
                        </p>
                      </div>
                    </div> -->
                    <template v-if="!item.data.resumptionDate">
                      <div
                        class="w-full"
                        @click="handleOptionClick('delete', item.data)"
                      >
                        <div
                          class="w-full flex justify-start items-center gap-2 cursor-pointer"
                        >
                          <Icon
                            icon-name="delete"
                            class-name="text-flame"
                            size="xs"
                          />
                          <p class="font-normal text-sm text-darkPurple">
                            Delete
                          </p>
                        </div>
                      </div>
                    </template>
                    <div
                      class="w-full"
                      @click="handleOptionClick('disengage', item.data)"
                    >
                      <div
                        class="w-full flex justify-start items-center gap-2 cursor-pointer"
                      >
                        <Icon
                          icon-name="icon-terminate"
                          class-name="text-desire"
                          size="xs"
                        />
                        <p class="font-normal text-sm text-darkPurple">
                          Disengage
                        </p>
                      </div>
                    </div>
                  </div>
                </Menu>
              </span>
            </template>
          </sTable>
        </div>
      </div>
    </div>
    <div v-else>
      <error-page />
    </div>
    <add-employee-from-people
      :open-modal="openEmployee"
      @close="$emit('close')"
    />
    <import-employee
      :open-modal="openImportEmployee"
      @close="$emit('closeImport')"
      @reload-employees="reloadEmployees()"
    />
    <transfer-employee
      :open-modal="openTransferEmployeeModal"
      :user-details="userDetails"
      @submitted="queryUpdate(), (openTransferEmployeeModal = false)"
      @close="(openTransferEmployeeModal = false), getEmployeeDetails()"
    />
    <suspend-employee
      :open-modal="openSuspendModal"
      :user-details="userDetails"
      @close="(openSuspendModal = false), getEmployeeDetails()"
    />
    <promote-employee
      :open-modal="openPromoteModal"
      :user-details="userDetails"
      @close="(openPromoteModal = false), getEmployeeDetails()"
    />
    <update-employee
      :open-modal="openUpdateEmployeeModal"
      :user-details="userDetails"
      @close="(openUpdateEmployeeModal = false), getEmployeeDetails()"
    />
    <revise-employee
      :open-modal="openReviseModal"
      ref="revise_salary"
      :user-details="userDetails"
      @close="(openReviseModal = false), getEmployeeDetails()"
    />
    <DisengageEmployee
      ref="disengage_employee"
      @success="getEmployeeDetails()"
    />
    <Modal v-if="dialogDel">
      <Card class="p-5 flex flex-col max-w-md">
        <div class>
          <p class="font-bold ml-4 my-5 text-center">
            Are you sure you want to delete this Employee?
          </p>
          <div class="flex">
            <Button
              class="text-white"
              :background="appearance.buttonColor || defaultBTNColor"
              @click="deleteEmployee"
            >
              Yes
            </Button>
            <Button
              background-color="none"
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4"
              @click="dialogDel = false"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Card>
    </Modal>

    <FilterTable
      v-if="filterTable"
      :filter-data="filterArray"
      @close="closeFilter($event)"
      @getParams="searchFilter($event)"
    />
  </div>
</template>

<script>
import STable from "@scelloo/cloudenly-ui/src/components/table";
import { mapState } from "vuex";
import Card from "@/components/Card";
import Button from "@/components/Button";
import CardFooter from "@/components/CardFooter";
import ErrorPage from "@/modules/Admin/error403";
import Menu from "@/components/Menu";
import Modal from "@/components/Modal";
import FilterTable from "@/components/FilterTable";
import TransferEmployee from "./sideActions/transferEmployee";
import SuspendEmployee from "./sideActions/suspendEmployee";
import PromoteEmployee from "./sideActions/promoteEmployee";
import ReviseEmployee from "./sideActions/reviseEmployee";
import DisengageEmployee from "./sideActions/disengageEmployee";
import AddEmployeeFromPeople from "./sideActions/addEmployeeFromPeople";
import ImportEmployee from "./sideActions/importEmployee";
import UpdateEmployee from "./sideActions/updateEmployeeCredentials";

export default {
  name: "AllEmployees",
  components: {
    STable,
    Menu,
    Card,
    CardFooter,
    AddEmployeeFromPeople,
    ErrorPage,
    ImportEmployee,
    Button,
    TransferEmployee,
    SuspendEmployee,
    PromoteEmployee,
    Modal,
    ReviseEmployee,
    UpdateEmployee,
    DisengageEmployee,
    FilterTable,
  },
  props: {
    openEmployee: {
      type: Boolean,
      default: false,
    },
    openImportEmployee: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      functions: [],
      itemsPerPage: null,
      numberOfPage: null,
      searchWord: null,
      isActive: null,
      dialogDel: false,
      filterTable: false,
      sortType: null,
      functionIds: [],
      filterArray: [
        { header: "Function(s)", value: "functionId", optionsBox: [] },
        { header: "Designation(s)", value: "designationId", optionsBox: [] },
        { header: "Location(s)", value: "locationId", optionsBox: [] },
        { header: "Job Level(s)", value: "levelId", optionsBox: [] },
      ],
      designationIds: [],
      userDetails: {},
      openTransferEmployeeModal: false,
      openUpdateEmployeeModal: false,
      openSuspendModal: false,
      openPromoteModal: false,
      openReviseModal: false,
      openDisengageModal: false,
      showPrivilegeError: false,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.employee.loading,
      employees: (state) => state.employee.employees,
      metaData: (state) => state.employee.metaData,
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
    employeeHeader() {
      if (
        this.$store.state.subscription &&
        this.$store.state.subscription.plan === "basic"
      ) {
        return [
          { title: "Employee", value: "userId" },
          { title: "Department", value: "orgFunction" },
          { title: "Job Title", value: "userDesignation" },
          { title: "Employment type", value: "employmentType" },
          { title: "Engagement Date", value: "resumptionDate" },
          { title: "", value: "id", image: true },
        ];
      }
      return [
        { title: "Employee", value: "userId" },
        { title: "FUNCTION", value: "orgFunction" },
        { title: "DESIGNATION", value: "userDesignation" },
        { title: "Job Level", value: "userLevel" },
        { title: "Employment type", value: "employmentType" },
        { title: "1st Line Reporting", value: "userReportingTo" },
        { title: "Engagement Date", value: "resumptionDate" },
        { title: "Ongoing Action", value: "temporaryStatus" },
        { title: "", value: "id", image: true },
      ];
    },
  },
  methods: {
    async deleteEmployee() {
      try {
        await this.$_deleteEmployee(this.userDetails.userId);
        this.dialogDel = false;
        this.getEmployeeDetails();
        this.$emit("reload");
        this.$toasted.success("Employee deleted successfully", {
          duration: 3000,
        });
      } catch (error) {
        this.$toasted.error("Employee can not be deleted", {
          duration: 3000,
        });
      }
    },
    viewFilterTable(value) {
      if (value) {
        this.filterTable = true;
      }
    },
    queryUpdate() {
      const isActive = this.isActive ? `&isActive=${this.isActive}` : "";
      const search = this.searchWord ? `&search=${this.searchWord}` : "";
      const sort = this.sortType ? `&sort=${this.sortType}` : "";
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      this.$store.dispatch(
        "employee/getEmployeesWithQueries",
        `${isActive}${sort}${search}${pageNumber}${itemPage}`
      );
    },
    handleOptionSelect(option) {
      if (option === "Active") {
        this.isActive = true;
        return this.queryUpdate();
      }
      if (option === "Deactivated") {
        this.isActive = false;
        return this.queryUpdate();
      }
      this.isActive = null;
      return this.queryUpdate();
    },
    checkIfPresent(item, itemArray) {
      const itemIndex = itemArray.indexOf(item);
      if (itemIndex >= 0) {
        itemArray.splice(itemIndex, 1);
      } else {
        itemArray.push(item);
      }
      return itemArray;
    },
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },
    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },
    async print() {
      await this.$htmlToPaper("printMe");
    },
    handleSearch(searchWord) {
      if (searchWord) {
        this.searchWord = searchWord;
        this.queryUpdate();
      } else {
        this.searchWord = null;
        this.queryUpdate();
      }
    },
    handleSort(sortType) {
      this.sortType = sortType;
      this.queryUpdate();
    },
    async handleOptionClick(slug, userDetails) {
      this.userDetails = userDetails;
      /* eslint-disable no-fallthrough */
      switch (slug) {
        case "view":
          return this.viewEmployeeDetails(userDetails);
        // case "paySlip":
        //   return this.viewPaySlip(userDetails.userId);
        case "update":
          try {
            await this.$handlePrivilege(
              "employeeDirectory",
              "resendEmployeesCredentials"
            );
            this.openUpdateEmployeeModal = true;
            return this.openUpdateEmployeeModal;
          } catch (error) {
            return this.$toasted.error(
              "You do not have permission to perform this task",
              {
                duration: 5000,
              }
            );
          }
        case "suspend":
          try {
            await this.$handlePrivilege(
              "employeeDirectory",
              "suspendEmployees"
            );
            this.openSuspendModal = true;
            return this.openSuspendModal;
          } catch (error) {
            return this.$toasted.error(
              "You do not have permission to perform this task",
              {
                duration: 5000,
              }
            );
          }
        case "delete":
          try {
            await this.$handlePrivilege("employeeDirectory", "deleteEmployees");
            this.dialogDel = true;
            return this.dialogDel;
          } catch (error) {
            return this.$toasted.error(
              "You do not have permission to perform this task",
              {
                duration: 5000,
              }
            );
          }
        case "salary":
          try {
            await this.$handlePrivilege(
              "employeeDirectory",
              "reviseEmployeeSalary"
            );
            this.openReviseModal = true;
            return this.$refs.revise_salary.toggle(userDetails);
          } catch (error) {
            return this.$toasted.error(
              "You do not have permission to perform this task",
              {
                duration: 5000,
              }
            );
          }
        case "disengage":
          try {
            await this.$handlePrivilege(
              "employeeDirectory",
              "disengageEmployees"
            );
            this.$router.push({
              name: "DisengageEmployees",
              params: { id: userDetails.userId }
            });
          } catch (error) {
            return this.$toasted.error(
              "You do not have permission to perform this task",
              {
                duration: 5000,
              }
            );
          }

        default:
          return "";
      }
    },
    async viewEmployeeDetails(details) {
      try {
        await this.$handlePrivilege("employeeDirectory", "viewEmployeeDetails");
        this.$router.push({
          name: "EditPersonalInfo",
          params: {
            id: details.userId,
          },
          query: {
            currentTab: "Personal Info",
            user: `${details.fname} ${details.lname}`,
          },
        });
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    async viewPaySlip(userId) {
      try {
        await this.$handlePrivilege("employeeDirectory", "viewPayslip");
        this.$router.push({ name: "viewEmployee", params: { id: userId } });
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    reload() {
      this.$store.dispatch("employee/get_Employees");
    },
    async getEmployeeDetails() {
      try {
        await this.$handlePrivilege(
          "employeeDirectory",
          "viewAllEmployeesRegister"
        );
        this.$store.dispatch(
          "employee/getEmployeesWithQueries",
          "&page=1&perPage=50"
        );
        this.showPrivilegeError = false;
      } catch (error) {
        this.showPrivilegeError = true;
      }
    },

    searchFilter(params) {
      this.$store.dispatch("employee/getEmployeesWithQueries", `${params}`);
      this.filterTable = false;
    },

    getAllFunctions() {
      this.$_getFunction().then((response) => {
        response.data.functions.map((functionVal) => {
          this.filterArray.forEach((filter) => {
            if (filter.header === "Function(s)") {
              filter.optionsBox.push({
                id: functionVal.id,
                name: functionVal.name,
              });
            }
          });
          return {};
        });
      });
    },

    getAllDesignations() {
      this.$_getDesignations("").then((response) => {
        response.data.designations.map((designation) => {
          this.filterArray.forEach((filter) => {
            if (filter.header === "Designation(s)") {
              filter.optionsBox.push({
                id: designation.id,
                name: designation.name,
              });
            }
          });
          return {};
        });
      });
    },

    getAllLocations() {
      this.$_getHumanarLocations("").then((response) => {
        response.data.outlets.map((outlet) => {
          this.filterArray.forEach((filter) => {
            if (filter.header === "Location(s)") {
              filter.optionsBox.push({
                id: outlet.id,
                name: outlet.name,
              });
            }
          });
          return {};
        });
      });
    },

    getAllLevels() {
      this.$_getLevels().then((response) => {
        response.data.levels.map((outlet) => {
          this.filterArray.forEach((filter) => {
            if (filter.header === "Job Level(s)") {
              filter.optionsBox.push({
                id: outlet.id,
                name: outlet.name,
              });
            }
          });
          return {};
        });
      });
    },

    closeFilter(val) {
      if (val) {
        this.filterTable = false;
      }
    },
    reloadEmployees(){
      this.getEmployeeDetails()
      this.$emit("reload");
    }
  },
  mounted() {
    this.getEmployeeDetails();
    this.getAllFunctions();
    this.getAllDesignations();
    this.getAllLocations();
    this.getAllLevels();
  },
};
</script>

<style></style>
